// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkUZB22-zcdI9K0j-ZjQastRg43deECZ4",
  authDomain: "lydiarx-001.firebaseapp.com",
  projectId: "lydiarx-001",
  storageBucket: "lydiarx-001.appspot.com",
  messagingSenderId: "469681685711",
  appId: "1:469681685711:web:960dec6e2dc931321623ba",
  measurementId: "G-45BVMELSGH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app)
const analytics = getAnalytics(app);