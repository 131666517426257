import Footer from "../../../components/footer"
import Nav from "../../../components/nav"
import LRxWebArt from "../../../assets/images/LydiaRx_web-art.svg"
import TransitionScreen from "../../../components/transitionScreen"

function PressReleasePage() {

    return(
        <div className="page-wrapper">
            <Nav />
            <TransitionScreen />
            <main className="main-wrapper">
                <section className="section_product-header">
                    <div className="page-padding">
                        <div className="container-large" style={{position: "relative"}}>
                            <div className="max-width-medium">
                                <h1>
                                    Contact Us
                                </h1>
                                <div className="padding-bottom"/>
                                <p className="Paragraph">Digital compliance for pharma and fast growing biotech</p>
                            </div>
                            <div class="ps_header_shapes">
                                <img src={LRxWebArt} className="ps_header-image" alt="LydiaRx Logo Art"/>
                            </div>
                        </div> 
                    </div>
                </section>
                <section className="section_about-us">
                    <div className="page-padding">
                        <div className="container-large">
                            <div className="padding-section-huge">
                                <div className="about_component">
                                    
                                    <div className="max-width-large">   
                                        <h2>Expertise and Client Focus in CSV Solutions and Services</h2>
                                        <div className="padding-bottom"/>
                                        
                                    </div>
                                    <div className="margin-vertical margin-xxlarge">
                                        {/* <div className="about_image-wrapper">
                                            <img src={AboutImage} className="about_image"/>
                                        </div> */}
                                    </div>
                                    <div className="max-width-large">
                                        <h4>Develop CSV frameworks</h4  >
                                        <ul style={{margin: "2rem"}}>
                                            <li>CSV program SOP</li>
                                            <li>
                                                CSV lifecycle documentation templates 
                                                (e.g. SIA, URS, FRA, validation plan, 
                                                validation summary report, qualification plans, RTM)
                                            </li>
                                            <li> 
                                                CSV supporting procedures (backup and
                                                restore, archival, business continuity,
                                                disaster recovery, audit trail
                                                management, access management,
                                                system admin procedures)
                                            </li>                                      
                                        </ul>
                                    </div>
                                    <div className="padding-bottom"/>
                                    <div className="max-width-large">
                                        <h4>Validate computerized systems</h4  >
                                        <ul style={{margin: "2rem"}}>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Laboratory systems</span> (e.g. LIMS, unicorn,
                                                empower, ELN, agilent, envision,
                                                softmax, lightcycler, solovpe)</li>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Manufacturing systems</span> (e.g DeltaV, MES)
                                            </li>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Document management systems</span> (e.g.veeva, mastercontrol, compliancequest)
                                            </li>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Other Enterprise Systems</span> (e.g maximo, LMS, ERP, vaisala, Vbas)
                                            </li>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Cloud systems</span> (e.g. docusign, oracle, azure, AWS)
                                            </li>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Standalone computerized equipment</span>
                                            </li>                 
                                        </ul>
                                    </div>
                                    <div className="padding-bottom"/>
                                    <div className="max-width-large">
                                        <h4>Implement data integrity program</h4  >
                                        <ul style={{margin: "2rem"}}>
                                            <li>
                                                Develop data integrity procedures (e.g DI
                                                policy, data management, audit trail review,
                                                data flow mapping sops)
                                            </li>
                                            <li>
                                            Develop data maps and establish data criticality standards
                                            </li>                
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}
export default PressReleasePage