/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 LydiaRx_border-scene.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function LydiaRxBorderScene(props) {
  const { nodes, materials } = useGLTF('/LydiaRx_border-scene.gltf')
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} castShadow={false}/>
        <mesh geometry={nodes.mesh_1.geometry} material={nodes.mesh_1.material} />
        <mesh geometry={nodes.mesh_2.geometry} material={nodes.mesh_2.material} />
        <mesh geometry={nodes.mesh_3.geometry} material={nodes.mesh_3.material} />
        <mesh geometry={nodes.mesh_4.geometry} material={nodes.mesh_4.material} />
      </group>
      {/* <directionalLight intensity={1} decay={2} position={[5, 10, 3.165]} /> */}
    </group>
  )
}

useGLTF.preload('/LydiaRx_border-scene.gltf')
