/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 LydiaRx-semi-truck.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function LydiaRxSemiTruck(props) {
  const { nodes, materials } = useGLTF('/LydiaRx_semi-truck.gltf')
  return (
    <group ref={props.truckRef} {...props} dispose={null}>
      <group position={[0, -0.56, 0]}>
        <group position={[-35.514, 0, -49.039]} rotation={[-Math.PI / 2, 0, 0]}>
          <group ref={props.frontwheelRef} position={[57.395, -45.119, 1.037]} rotation={[0, -0.031, 0]}>
            <mesh geometry={nodes['Exported_from_Blender-2901_6'].geometry} material={nodes['Exported_from_Blender-2901_6'].material} position={[0, -0.006, 0]} />
            <mesh geometry={nodes['Exported_from_Blender-2901_7'].geometry} material={nodes['Exported_from_Blender-2901_7'].material} position={[0, -2.125, 0]} />
            <mesh geometry={nodes['Exported_from_Blender-2901_8'].geometry} material={nodes['Exported_from_Blender-2901_8'].material} position={[0, 0.137, 0]} />
            <mesh geometry={nodes['Exported_from_Blender-2901_9'].geometry} material={nodes['Exported_from_Blender-2901_9'].material} position={[0, -2.259, 0]} />
          </group>
          <group ref={props.leftDoorRef} position={[41.709, -44.913, 3.075]} rotation={[0,0, props.doorLeft? -props.doorLeft: 0]}>
            <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} position={[0.048, -0.648, 0]} />
          </group>
          <group ref={props.rightDoorRef} position={[41.688, -47.45, 3.075]} rotation={[0,0, props.doorRight? props.doorRight: 0]}>
            <mesh geometry={nodes.mesh_1.geometry} material={nodes.mesh_1.material} position={[0.069, 0.634, 0]} />
          </group>
          <mesh geometry={nodes.mesh_2.geometry} material={nodes.mesh_2.material} />
          <mesh geometry={nodes['Exported_from_Blender-2901'].geometry} material={nodes['Exported_from_Blender-2901'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_1'].geometry} material={nodes['Exported_from_Blender-2901_1'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_2'].geometry} material={nodes['Exported_from_Blender-2901_2'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_3'].geometry} material={nodes['Exported_from_Blender-2901_3'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_4'].geometry} material={nodes['Exported_from_Blender-2901_4'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_5'].geometry} material={nodes['Exported_from_Blender-2901_5'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_10'].geometry} material={nodes['Exported_from_Blender-2901_10'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_11'].geometry} material={nodes['Exported_from_Blender-2901_11'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_12'].geometry} material={nodes['Exported_from_Blender-2901_12'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_13'].geometry} material={nodes['Exported_from_Blender-2901_13'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_14'].geometry} material={nodes['Exported_from_Blender-2901_14'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_15'].geometry} material={nodes['Exported_from_Blender-2901_15'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_16'].geometry} material={nodes['Exported_from_Blender-2901_16'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_17'].geometry} material={nodes['Exported_from_Blender-2901_17'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_18'].geometry} material={nodes['Exported_from_Blender-2901_18'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_19'].geometry} material={nodes['Exported_from_Blender-2901_19'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_20'].geometry} material={nodes['Exported_from_Blender-2901_20'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_21'].geometry} material={nodes['Exported_from_Blender-2901_21'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_22'].geometry} material={nodes['Exported_from_Blender-2901_22'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_23'].geometry} material={nodes['Exported_from_Blender-2901_23'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_24'].geometry} material={nodes['Exported_from_Blender-2901_24'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_25'].geometry} material={nodes['Exported_from_Blender-2901_25'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_26'].geometry} material={nodes['Exported_from_Blender-2901_26'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_27'].geometry} material={nodes['Exported_from_Blender-2901_27'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_28'].geometry} material={nodes['Exported_from_Blender-2901_28'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_29'].geometry} material={nodes['Exported_from_Blender-2901_29'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_30'].geometry} material={nodes['Exported_from_Blender-2901_30'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_31'].geometry} material={nodes['Exported_from_Blender-2901_31'].material} />
          <mesh geometry={nodes['Exported_from_Blender-2901_32'].geometry} material={nodes['Exported_from_Blender-2901_32'].material} />
          <mesh ref={props.backWheel1Ref} geometry={nodes.mesh_36.geometry} material={nodes.mesh_36.material} position={[44.346, -46.195, 1.059]} rotation={[0, 0.192, 0]} />
          <mesh ref={props.backWheel2Ref} geometry={nodes.mesh_37.geometry} material={nodes.mesh_37.material} position={[43.284, -46.195, 1.059]} rotation={[0, 0.157, 0]} />
        </group>
      </group>
      {/* <directionalLight intensity={1} decay={2} position={[5, 10, 3.165]} /> */}
    </group>
  )
}

useGLTF.preload('/LydiaRx_semi-truck.gltf')
