import { Helmet } from 'react-helmet-async'

import Footer from "../../../components/footer"
import Nav from "../../../components/nav"
import LRxWebArt from "../../../assets/images/LydiaRx_web-art.svg"
import TransitionScreen from "../../../components/transitionScreen"

function DataAnalyticsPage() {

    return(
        <div className="page-wrapper">
            <Helmet>
                <title>Data & Analysis - LydiaRx</title>
                <meta property="description" content="Unlock the power of data in pharmaceutical decision-making with LydiaRx's advanced analytics services. From predictive modeling to real-time monitoring, our data-driven approach empowers informed decisions and drives innovation in pharmaceutical research and development." />
                <meta property="og:title" content="Data & Analysis - LydiaRx" />
                <meta property="og:description" content="Unlock the power of data in pharmaceutical decision-making with LydiaRx's advanced analytics services. From predictive modeling to real-time monitoring, our data-driven approach empowers informed decisions and drives innovation in pharmaceutical research and development." />
            </Helmet>
            <Nav />
            <TransitionScreen />
            <main className="main-wrapper">
                <section className="section_product-header">
                    <div className="page-padding">
                        <div className="container-large" style={{position: "relative"}}>
                            <div className="max-width-medium">
                                <h1>
                                    Data & Analytics
                                </h1>
                                <div className="padding-bottom"/>
                                <p className="Paragraph">Empowering pharma supply chains through data excellence</p>
                            </div>
                            <div class="ps_header_shapes">
                                <img src={LRxWebArt} className="ps_header-image" alt="LydiaRx Logo Art"/>
                            </div>
                        </div> 
                    </div>
                </section>
                <section className="section_about-us">
                    <div className="page-padding">
                        <div className="container-large">
                            <div className="padding-section-huge">
                                <div className="about_component">
                                    
                                    <div className="max-width-large">
                                        <p>
                                            In the dynamic landscape of the pharmaceutical industry, effective supply chain management is paramount. However, it's often fraught with challenges such as stringent regulatory requirements, complex distribution networks, and the need for stringent quality control measures. In such a high-stakes environment, the ability to harness data effectively becomes a strategic imperative. That's where our Data & Analysis Services come into play, offering tailored solutions to navigate these complexities and drive operational excellence.
                                        </p>
                                    </div>
                                    <div className="margin-vertical margin-xxlarge">
                                        {/* <div className="about_image-wrapper">
                                            <img src={AboutImage} className="about_image"/>
                                        </div> */}
                                    </div>
                                    <div className="max-width-large">
                                        <h4>Key issues in pharmaceutical supply chain management include:</h4  >
                                        <ul style={{margin: "2rem"}}>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Regulatory Compliance: </span>Adhering to various regulatory standards across different regions demands meticulous tracking and documentation of every stage of the supply chain.
                                            </li>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Product Authentication: </span>Ensuring the authenticity and integrity of pharmaceutical products throughout the supply chain is critical to combating counterfeit drugs and safeguarding public health.
                                            </li>
                                            <li> 
                                                <span style={{fontWeight: "bold"}}>Inventory Management: </span>Optimizing inventory levels while minimizing the risk of stockouts or excess inventory is a delicate balance that requires real-time visibility and predictive analytics.
                                            </li>                                      
                                            <li> 
                                                <span style={{fontWeight: "bold"}}>Demand Forecasting: </span>Accurately forecasting demand for pharmaceutical products is challenging due to factors such as seasonality, market trends, and unforeseen events like pandemics or regulatory changes.
                                            </li>                                      
                                        </ul>
                                    </div>
                                    <div className="padding-bottom"/>
                                    <div className="max-width-large">
                                        <h4>Our Data & Analysis Services address these challenges head-on, offering a range of solutions tailored to the unique needs of the pharmaceutical industry:</h4  >
                                        <ul style={{margin: "2rem"}}>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Data Visualizations: </span>Transforming complex datasets into intuitive visual representations empowers stakeholders to gain actionable insights at a glance, facilitating informed decision-making.
                                            </li>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Real-Time Monitoring: </span>Track key metrics and performance indicators in real-time to proactively identify and address issues before they escalate, ensuring operational efficiency and compliance.
                                            </li>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Trend Analysis: </span>Uncover patterns and trends within your data to anticipate market shifts, optimize resource allocation, and capitalize on emerging opportunities.
                                            </li>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Predictive Modeling: </span>Leverage advanced analytics and machine learning algorithms to forecast demand, optimize inventory levels, and mitigate supply chain risks.
                                            </li>
                                            <li>
                                                <span style={{fontWeight: "bold"}}>Custom Analytics Solutions: </span>Our team of experts works closely with you to develop custom analytics solutions tailored to your specific business objectives and operational challenges.
                                            </li>              
                                        </ul>
                                    </div>
                                    <div className="padding-bottom"/>
                                    <div className="max-width-large">
                                        <p>
                                            With our Data & Analysis Services, you can harness the power of data to drive innovation, enhance efficiency, and maintain a competitive edge in the fast-paced world of pharmaceutical supply chain management.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}
export default DataAnalyticsPage